import styled from 'styled-components';
import { TabContentWrapper } from '../UI/styledConstants';

export const Wrapper = styled(TabContentWrapper)`
	.payee-actions {
		button {
			max-width: 78px;
		}
	}
	.beneficiary-list-heading {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-bottom: 15px;
		h2 {
			margin: 0;
		}
		button {
			max-width: 180px;
		}
		.limit-with-action {
			display: flex;
			align-items: center;
			.limit-available {
				margin-right: 20px;
				white-space: nowrap;
			}
		}
	}
	.pay-modal-form {
		padding: 20px 0 0 0;
		.form-field {
			margin-bottom: 15px;
		}
	}
	@media only screen and (max-width: 767px) {
		.limit-with-action {
			display: block;
		}
		.beneficiary-list-heading h2 {
			font-size: 16px;
		}
	}
`;
