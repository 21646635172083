import styled from 'styled-components';

export const InputWrapper = styled.div`
	position: relative;
	.input-text {
		border: 1px solid #cbcbcb;
		box-shadow: 0px 2px 2px #f6f6f6;
		background-color: #fff;
		font: normal normal normal 20px;
		height: ${({ height }) => (height ? `${height}` : '50px')};
		width: ${({ width }) => (width ? `${width}` : '100%')};
		padding: 10px 20px;
		color: #333;
		&:focus + .placeholder-text .text,
		&:not(input[value='']) + .placeholder-text .text {
			background-color: #fff;
			font: normal normal normal 13px;
			transform: translate(0, -124%);
		}
	}
	.placeholder-text {
		font: normal normal normal 14px;
		color: #777;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: transparent;
		display: flex;
		align-items: center;
		pointer-events: none;
		padding: 10px 15px;
		.text {
			padding: 0 5px;
			transform: translate(0);
			transition: transform 0.15s ease-out, font-size 0.15s ease-out, background-color 0.2s ease-out, color 0.15s ease-out;
		}
	}
`;

export const CheckboxWrapper = styled.div`
	label {
		display: flex;
		align-items: center;
		gap: 9px;
	}
`;
