import React from 'react';
import './Contactus.css';
import location from '../../assets/images/location.png';
import envelop from '../../assets/images/envelop.png';
import mobilebutton from '../../assets/images/mobilebutton.png';
function Contactus() {
	return (
		<div className="content-wrapper">
			<div className="heading">
				<h2 className="m-auto">Contact Us</h2>
			</div>
			<div className="contactuswrapper">
				<div className="containers">
					<div className="heading-contact text-center">
						<label className="m-auto">
							We’d Love To <strong>Help You</strong>
						</label>
					</div>
					<div className="consumer-top  consumer-middle flex justify-center gap-100">
						<div className="contactus">
							<iframe
								src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14021.96817482791!2d77.06945884138463!3d28.524926611574227!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1be7697b4cc1%3A0xaa5438802f6a5895!2sKapas%20Hera%20Extension%2C%20Kapas%20Hera%2C%20New%20Delhi%2C%20Delhi!5e0!3m2!1sen!2sin!4v1707382408615!5m2!1sen!2sin"
								width="100%"
								height="100%"
								allowfullscreen=""
								loading="lazy"
								referrerpolicy="no-referrer-when-downgrade"
								title="map"
							></iframe>
							<div className="contactusdetail">
								<div className="contactaddress">
									<div className="address">
										<label>Registered Address</label>
									</div>
									<div className="address">
										<img src={location} alt="location" />
										<p>
										Kapas Hera Extension, Kapas Hera, Gurugram, New Delhi, Haryana 110037
										</p>
									</div>
								</div>
								<div className="contactaddress">
									<div className="address">
										<label>Contact Us</label>
									</div>
									<div className="address">
										<img src={envelop} alt="envelop" />
										<p>
											<strong>Email Id :</strong>support@techfora.com
										</p>
									</div>
									<div className="address">
										<img src={mobilebutton} alt="mobilebutton" />
										<p>
											<strong>Phone No :</strong>9616062254
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Contactus;
