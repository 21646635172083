import React, { useEffect, useState } from 'react';
import { registerSenderRequest, verifySenderRequest, verifySenderStatusRequest, deleteBeneficiaryRequest } from '../../utils/api';
import OfferBanner from '../OfferBanner';
import { AlertWrapper, TableWrapper } from '../UI/styledConstants';
import { Wrapper } from './style';
import { isEmpty, isValidMobileNumber } from '../../utils/common';
import PayModal from './PayModal';
import AddBeneficiaryModal from './AddBeneficiary';
import OtpModal from './OtpModal';
import Conform from './Conform';
import Loader from '../UI/Loader/Loader';
import Receipt from './Receipt';
import IconClose from '../../assets/images/IconClose';

// const selectType = [
// 	{ value: 'SEND_MONEY', label: 'Send Money' },
// 	{ value: 'PAST_TRANSACTIONS', label: 'Past Transactions' },
// 	{ value: 'ADD_NEW_PAYEE', label: 'Add New Payee' },
// ];

const initControls = {
	firstName: '',
	lastName: '',
	merchantId: '',
	mobileNumber: '',
	pinCode: '',
};

export default function Sendmoney({ className = '' }) {
	const [senderMobile, setSenderMobile] = useState('');
	const [activeMobilePop, setActiveMobilePop] = useState(false);
	const [beneficiaryDetails, setBeneficiaryDetails] = useState(null);
	const [selectType, setSelectType] = useState('SEND_MONEY');
	const [controls, setControls] = useState({ ...initControls });
	const [modal, setModal] = useState({ name: '', modalData: null });
	const [loding, setLoding] = useState(false);
	const [lodingDelete, setLodingDelete] = useState(false);
	const [overlayer, setOverlayer] = useState(false);
	const [deleteUserData, setDeleteUserData] = useState({});
	const [message, setMessage] = useState({});
	const [otpMsg, setOtpMsg] = useState('');
	const onSelectTypeChange = (e) => {
		if (isEmpty(sessionStorage.getItem('ID Token'))) {
			setMessage({ type: 'error', msg: 'Please login first to continue', place: 'globle' });
			return;
		}
		setSelectType(e.target.value);
	};

	const numberReg = /^[0-9]+$/;
	const nameReg = /^[A-Za-z\s]+$/i;
	const onChangeHandler = (e) => {
		if (isEmpty(sessionStorage.getItem('ID Token'))) {
			setMessage({ type: 'error', msg: 'Please login first to continue', place: 'globle' });
			return;
		}

		const { name, value } = e.target;
		if (name === 'mobileNumber' || name === 'pinCode') {
			if (!numberReg.test(value) && value) {
				return false;
			}
		}

		if (name === 'firstName' || name === 'lastName') {
			if (!nameReg.test(value) && value) {
				return false;
			}
		}

		const _controls = {
			...controls,
			[name]: value,
		};

		setControls(_controls);
	};

	const verifySenderStatus = async (mobileNumber) => {
		if (!isEmpty(mobileNumber)) {
			if (!isValidMobileNumber(mobileNumber)) {
				setMessage({ type: 'error', msg: 'Please enter valid mobile', place: 'form' });
				return false;
			}
			setLoding(true);
			const response = await verifySenderStatusRequest(mobileNumber);

			if (response?.success) {
				setBeneficiaryDetails(response?.data);
				setActiveMobilePop(true);
			} else {
				setBeneficiaryDetails(null);
				setMessage({ type: 'error', msg: response?.msg, place: 'globle' });
				if (response?.errorCodeList.length > 0) {
					setMessage({ type: 'error', msg: response?.errorCodeList[0], place: 'globle' });
				} else {
					setMessage({ type: 'error', msg: response?.msg, place: 'globle' });
				}
			}

			setLoding(false);
		} else {
			setMessage({ type: 'error', msg: 'Please enter valid mobile number', place: 'form' });
			setLoding(false);
		}
	};

	const registerSender = async (resend = '') => {
		if (isEmpty(controls.firstName)) {
			setMessage({ type: 'error', msg: 'Please enter first name', place: 'form' });
			return false;
		} else if (isEmpty(controls.lastName)) {
			setMessage({ type: 'error', msg: 'Please enter last name', place: 'form' });
			return false;
		} else if (isEmpty(controls.mobileNumber)) {
			setMessage({ type: 'error', msg: 'Please enter mobile number', place: 'form' });
			return false;
		} else if (isEmpty(controls.pinCode)) {
			setMessage({ type: 'error', msg: 'Please enter pin code', place: 'form' });
			return false;
		}

		if (resend === 'resend') {
			setLodingDelete(true);
		} else {
			setLoding(true);
		}
		const response = await registerSenderRequest({ ...controls });
		setLoding(false);
		setLodingDelete(false);
		if (response?.success) {
			setModal({ name: 'VERIFY_SENDER_OTP_MODAL', modalData: response });
		} else {
			if (response?.errorCodeList.length > 0) {
				if (response?.errorCodeList[0] == 'Success') {
					setMessage({ type: 'error', msg: 'This user already exist', place: 'globle' });
					setControls({ ...initControls });
				} else {
					setMessage({ type: 'error', msg: response?.errorCodeList[0], place: 'globle' });
				}
			} else {
				setMessage({ type: 'error', msg: response?.msg, place: 'globle' });
			}
		}
	};

	const onPayClick = (data) => {
		setModal({ name: 'PAY_MODAL', modalData: data });
	};
	const onAddBeneficiaryClick = (data = null) => {
		setModal({ name: 'ADD_BENEFICIARY_MODAL', modalData: data });
	};

	const onCloseModal = () => {
		setModal({ name: '', modalData: null });
	};

	const onSubmitOtp = (otp = '') => {
		setOtpMsg('');
		if (isEmpty(otp)) {
			setOtpMsg('Please enter otp');
			return;
		}
		if (modal?.name === 'VERIFY_SENDER_OTP_MODAL') {
			setLodingDelete(true);
			verifySenderRequest({
				merchantId: '',
				otp: otp,
				otpReference: modal?.modalData?.data?.otpReference,
			}).then((resp) => {
				setLodingDelete(false);
				if (resp.success) {
					setModal({ name: '', modalData: null });
					verifySenderStatus(controls?.mobileNumber);
					setOtpMsg('');

					setSenderMobile(controls?.mobileNumber);
					setSelectType('ADD_NEW_PAYEE');
					setControls({ ...initControls });
				} else {
					if (resp?.errorCodeList.length > 0) {
						setOtpMsg(resp?.errorCodeList[0]);
					} else {
						setOtpMsg(resp?.msg);
					}
				}
			});
		}
		if (!isEmpty(otp) && modal?.name === 'VERIFY_SENDER_OTP_DELETE') {
			setLodingDelete(true);
			verifySenderRequest({
				merchantId: '',
				otp: otp,
				otpReference: modal?.modalData?.data?.otpReference,
			}).then((resp) => {
				setLodingDelete(false);

				if (resp?.success) {
					setModal({ name: '', modalData: null });
					setOtpMsg('');
					verifySenderStatus(senderMobile);
					setSenderMobile(controls?.mobileNumber);
					setSelectType('ADD_NEW_PAYEE');
					setControls({ ...initControls });
				} else {
					if (resp?.errorCodeList.length > 0) {
						setOtpMsg(resp?.errorCodeList[0]);
					} else {
						setOtpMsg(resp?.msg);
					}
				}
			});
		}
		if (!isEmpty(otp) && modal?.name === 'VERIFY_DDBENIFICILIA_OTP_MODAL') {
			setLodingDelete(true);
			setLoding(false);
			verifySenderRequest({
				merchantId: '',
				otp: otp,
				otpReference: modal?.modalData?.data?.data?.otpReference,
			}).then((resp) => {
				setLodingDelete(false);
				if (resp?.success) {
					setModal({ name: '', modalData: null });
					verifySenderStatus(modal.mobileNumber);
					setSenderMobile(modal.mobileNumber);
					setSelectType('ADD_NEW_PAYEE');
					setControls({ ...initControls });
				} else {
					if (resp?.errorCodeList.length > 0) {
						setOtpMsg(resp?.errorCodeList[0]);
					} else {
						setOtpMsg(resp?.msg);
					}
				}
			});
		}
	};

	const ochangeMobileNumber = (e) => {
		if (isEmpty(sessionStorage.getItem('ID Token'))) {
			setMessage({ type: 'error', msg: 'Please login first to continue', place: 'globle' });
			return;
		}

		if (!numberReg.test(e.target.value) && e.target.value) {
			return false;
		}
		setSenderMobile(e.target.value);
	};

	const deleteConfirm = (e, data) => {
		setOverlayer(true);
		setDeleteUserData(data);
	};

	const deleteHandeler = (resend = '') => {
		if ((resend = 'resend')) {
			setLodingDelete(true);
		} else {
			setLoding(true);
		}
		setOverlayer(false);

		const param = {
			beneficiaryId: deleteUserData?.id,
			merchantId: '',
			mobileNumber: senderMobile,
		};

		deleteBeneficiaryRequest(param).then((response) => {
			if ((resend = 'resend')) {
				setLodingDelete(false);
			} else {
				setLoding(false);
			}
			if (response.success) {
				setOverlayer(false);
				setModal({ name: 'VERIFY_SENDER_OTP_DELETE', modalData: response });
			} else {
				if (response?.errorCodeList.length > 0) {
					setMessage({ type: 'error', msg: response?.msg, place: 'globle' });
					setMessage({ type: 'error', msg: response?.errorCodeList[0], place: 'local' });
				} else {
					setMessage({ type: 'error', msg: response?.msg, place: 'globle' });
				}
			}
		});
	};

	useEffect(() => {
		if (!isEmpty(message)) {
			setTimeout(() => {
				setMessage({});
			}, 5000);
		}
	}, [message]);

	return (
		<Wrapper className={`tab-content-wrapper ${className}`}>
			<div className="tab-content-left">
				<h2>Money Transfer (DMT)</h2>
				<div className="form-field">
					{!isEmpty(message) && message.place === 'globle' && (
						<AlertWrapper className={`${message.type} globle`}>
							<span className="close" onClick={() => setMessage({})}>
								×
							</span>
							{message.msg}
						</AlertWrapper>
					)}
					<div className="radio-group" style={{ justifyContent: 'space-between' }}>
						<label className="radio">
							<input type="radio" name="selectType" value="SEND_MONEY" checked={selectType === 'SEND_MONEY'} onChange={onSelectTypeChange} /> Send Money
						</label>
						<label className="radio">
							<input type="radio" name="selectType" value="ADD_NEW_PAYEE" checked={selectType === 'ADD_NEW_PAYEE'} onChange={onSelectTypeChange} /> Register Sender
						</label>
					</div>
				</div>
				{selectType === 'SEND_MONEY' && (
					<>
						<div className="form-field">
							<input type="text" name="senderMobile" value={senderMobile} placeholder="Sender Mobile" maxLength="10" onChange={(e) => ochangeMobileNumber(e)} />
						</div>
						{!isEmpty(message) && message.place === 'form' && (
							<AlertWrapper className={message.type}>
								<span className="close" onClick={() => setMessage({})}>
									×
								</span>
								{message.msg}
							</AlertWrapper>
						)}

						<button type="submit" className="button-outline mt20" onClick={() => verifySenderStatus(senderMobile)}>
							VERIFY SENDER
						</button>
					</>
				)}
				{selectType === 'ADD_NEW_PAYEE' && (
					<>
						<div className="form-field">
							<input type="text" name="firstName" placeholder="First Name" value={controls?.firstName} onChange={onChangeHandler} />
						</div>
						<div className="form-field">
							<input type="text" name="lastName" placeholder="Last Name" value={controls?.lastName} onChange={onChangeHandler} />
						</div>
						<div className="form-field">
							<input type="text" name="mobileNumber" placeholder="Mobile Number" maxLength="10" value={controls?.mobileNumber} onChange={onChangeHandler} />
						</div>
						<div className="form-field">
							<input type="text" name="pinCode" placeholder="PIN Code" value={controls?.pinCode} maxLength="6" onChange={onChangeHandler} />
						</div>
						{!isEmpty(message) && (
							<AlertWrapper className={message.type}>
								<span className="close" onClick={() => setMessage({})}>
									×
								</span>
								{message.msg}
							</AlertWrapper>
						)}
						<button type="submit" className="button-outline mt20" onClick={registerSender}>
							ADD NEW SENDER
						</button>
					</>
				)}
			</div>
			{!isEmpty(beneficiaryDetails) && (
				<div className={`tab-content-right ${!activeMobilePop ? '' : 'active'}`}>
					<div
						className="d-m-block close-pop"
						onClick={() => {
							setActiveMobilePop(false);
						}}
					>
						<IconClose />
					</div>
					<div className="beneficiary-list-heading">
						<h2>Beneficiary Details</h2>
						<div className="limit-with-action">
							{beneficiaryDetails?.limitAvailable && (
								<div className="limit-available">
									Limit Available: <strong>{beneficiaryDetails?.limitAvailable}</strong>
								</div>
							)}
							{!isEmpty(beneficiaryDetails) && (
								<button type="submit" className="button-outline" onClick={onAddBeneficiaryClick}>
									ADD BENEFICIARY
								</button>
							)}
						</div>
					</div>
					{!isEmpty(beneficiaryDetails) && (
						<>
							<TableWrapper>
								<table className="table">
									<thead>
										<tr>
											<th>Beneficiary Name</th>
											<th>Account No.</th>
											<th>IFSC Code</th>
											<th>Bank</th>
											<th>Actions</th>
										</tr>
									</thead>
									<tbody>
										{(beneficiaryDetails?.beneficiaries || []).map((data, i) => (
											<tr key={i}>
												<td className="bold">{data?.name}</td>
												<td>{data?.account}</td>
												<td>{data?.ifsc}</td>
												<td>{data?.bank}</td>
												<td style={{ display: 'flex', gap: '5px' }}>
													<button type="button" style={{ minWidth: '80px' }} className="button-outline" onClick={() => onPayClick(data)}>
														PAY
													</button>
													<button type="button" style={{ minWidth: '80px' }} className="button-outline" onClick={(e) => deleteConfirm(e, data)}>
														Delete
													</button>
												</td>
											</tr>
										))}
										{isEmpty(beneficiaryDetails?.beneficiaries) && (
											<tr>
												<td colSpan={4}>No data found</td>
											</tr>
										)}
									</tbody>
								</table>
							</TableWrapper>
						</>
					)}
					{isEmpty(beneficiaryDetails) && <TableWrapper>Please verify sender to get beneficiaries.</TableWrapper>}
				</div>
			)}
			{isEmpty(beneficiaryDetails) && (
				<div className="tab-content-right">
					<OfferBanner />
				</div>
			)}

			{modal?.name === 'TRANSACTION_SUCCESS_MODAL' && <Receipt onCloseModal={onCloseModal} modalData={modal?.modalData} />}
			{modal?.name === 'PAY_MODAL' && (
				<PayModal
					modalData={modal?.modalData}
					setModal={setModal}
					mobileNumber={beneficiaryDetails?.mobile}
					onCloseModal={onCloseModal}
					verifySenderStatus={verifySenderStatus}
					setLoding={setLoding}
				/>
			)}
			{modal?.name === 'ADD_BENEFICIARY_MODAL' && (
				<AddBeneficiaryModal modalData={modal?.modalData} mobileNumber={beneficiaryDetails?.mobile} onCloseModal={onCloseModal} verifySenderStatus={verifySenderStatus} setModal={setModal} />
			)}
			{modal?.name === 'VERIFY_SENDER_OTP_MODAL' && (
				<OtpModal
					modalData={modal?.modalData}
					onCloseModal={onCloseModal}
					onSubmitOtp={onSubmitOtp}
					setOtpMsg={setOtpMsg}
					otpMsg={otpMsg}
					lodingDelete={lodingDelete}
					resendOtp={registerSender}
				/>
			)}
			{modal?.name === 'VERIFY_SENDER_OTP_DELETE' && (
				<OtpModal
					modalData={modal?.modalData}
					onCloseModal={onCloseModal}
					onSubmitOtp={onSubmitOtp}
					setOtpMsg={setOtpMsg}
					otpMsg={otpMsg}
					lodingDelete={lodingDelete}
					resendOtp={deleteHandeler}
				/>
			)}
			{modal?.name === 'VERIFY_DDBENIFICILIA_OTP_MODAL' && (
				<OtpModal modalData={modal?.modalData} onCloseModal={onCloseModal} onSubmitOtp={onSubmitOtp} setOtpMsg={setOtpMsg} otpMsg={otpMsg} lodingDelete={lodingDelete} />
			)}
			{overlayer && <Conform setOverlayer={setOverlayer} deleteUserData={deleteUserData} deleteHandeler={deleteHandeler} />}
			{loding && <Loader />}
		</Wrapper>
	);
}
