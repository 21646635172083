import { useState, useEffect } from 'react';

import config from '../../utils/config';
import Request from '../../utils/Request';
import { isEmpty } from '../../utils/common';
import { AlertWrapper, TabContentWrapper } from '../UI/styledConstants';
import Loader from '../UI/Loader/Loader';
import { rechargeDth } from '../../utils/api';
import OfferBanner from '../OfferBanner';
import CustomDropdown from '../CustomDropdown/CustomDropdown';

export default function Component({ className = '' }) {
	const [operatorList, setOperatorList] = useState([]);
	const [message, setMessage] = useState({});
	const [provider, setProvider] = useState('');
	const [formDetails, setFormDetails] = useState({
		dthNumber: '',
		operatorCode: '',
		rechargeAmount: '',
	});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const prov = provider.split(',');
		setFormDetails({
			...formDetails,
			operatorCode: prov[0],
		});
	}, [provider]);

	const operatorHandler = () => {
		setLoading(true);
		const successHandler = (data) => {
			setLoading(false);
			if (data.success) {
				setOperatorList(data.data);
			}
		};

		const errorHandler = () => {
			// setErrorMessage(data.msg);
			setLoading(false);
		};

		const api = new Request('', successHandler, errorHandler);
		return api.get(`${config.API_BASEPATH}${config.dth.OPERATOR}`, { headers: { Authorization: `Bearer ${sessionStorage.getItem('ID Token')}` } });
	};

	useEffect(() => {
		if (isEmpty(sessionStorage.getItem('ID Token'))) {
			return;
		}
		operatorHandler();
	}, []);

	const numberReg = /^[0-9]+$/;

	const rechargeFormHandler = (event) => {
		if (isEmpty(sessionStorage.getItem('ID Token'))) {
			setMessage({ type: 'error', msg: 'Please login first to continue', place: 'globle' });
			return;
		}

		if (event.target.name === 'rechargeAmount' || event.target.name === 'dthNumber') {
			if (!numberReg.test(event.target.value) && event.target.value) {
				return false;
			}
		}

		setFormDetails({
			...formDetails,
			[event.target.name]: event.target.value,
		});
	};

	const rechargeHandler = () => {
		if (isEmpty(formDetails.operatorCode)) {
			setMessage({ type: 'error', msg: 'Please select operator', place: 'local' });
			return;
		} else if (isEmpty(formDetails.dthNumber) || formDetails.dthNumber.length < 10) {
			setMessage({ type: 'error', msg: 'Please enter valid service number', place: 'local' });
			return;
		} else if (isEmpty(formDetails.rechargeAmount)) {
			setMessage({ type: 'error', msg: 'Please enter amount', place: 'local' });
			return;
		}
		setLoading(true);
		rechargeDth(formDetails).then((data) => {
			setLoading(false);
			if (data?.success) {
				setMessage({ type: 'success', msg: data?.msg, place: 'globle' });
			} else {
				if (data?.errorCodeList?.length > 0) {
					setMessage({ type: 'error', msg: data?.errorCodeList[0], place: 'globle' });
				} else {
					setMessage({ type: 'error', msg: data?.msg, place: 'globle' });
				}
			}
		});
	};

	useEffect(() => {
		if (!isEmpty(message)) {
			setTimeout(() => {
				setMessage({});
			}, 5000);
		}
	}, [message]);

	return (

		<TabContentWrapper className={`tab-content-wrapper mobile-recharge-form`}>
			{!isEmpty(message) && message.place === 'globle' && (
				<AlertWrapper className={`${message.type} globle`}>
					<span className="close" onClick={() => setMessage({})}>
						×
					</span>
					{message.msg}
				</AlertWrapper>
			)}
			<div className="tab-content-left">
				<h2>DTH Recharge</h2>

				<div className="form-field">
					<CustomDropdown list={operatorList} setProvider={setProvider} type="mobile" setMessage={setMessage} />
				</div>
				<div className="form-field">
					<input type="text" name="dthNumber" placeholder="Enter Service Number" value={formDetails.dthNumber} maxLength={10} onChange={rechargeFormHandler} />
				</div>
				<div className="form-field">
					<div>
						<input type="text" name="rechargeAmount" placeholder="Amount" value={formDetails.rechargeAmount} onChange={rechargeFormHandler} />
						<div></div>
					</div>
					{/* <div className="browse-plans" onClick={fetchPlan}>Fetch Plans</div>  */}
				</div>
				{!isEmpty(message) && message.place === 'local' && (
					<AlertWrapper className={`${message.type}`}>
						<span className="close" onClick={() => setMessage({})}>
							×
						</span>
						{message.msg}
					</AlertWrapper>
				)}
				<button type="submit" className="button-outline" onClick={rechargeHandler}>
					Continue
				</button>
			</div>
			<div className="tab-content-right">{<OfferBanner />}</div>
			{loading && <Loader />}
		</TabContentWrapper>
	);
}
