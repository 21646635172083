import styled from 'styled-components';

export const Wrapper = styled.div`
	display: flex;
	.i-icon {
		display: block;
		position: absolute;
		right: 9px;
		font-size: 16px;
		top: 50%;
		transform: translateY(-50%);
		z-index: 1;
		cursor: pointer;
	}
	.login-wrapper {
		display: flex;
		& > div {
			width: 50%;
		}
	}
	.left {
		flex: 1;
		padding: 36px 26px;
		background-color: #f6f6f6;
		h1 {
			font: normal normal bold 36px;
			line-height: 36px;
			color: #1e437a;
		}
		h2 {
			font: normal normal medium 25px;
			margin-top: 18px;
		}
		p {
			font: normal normal medium 13px/20px;
			margin-bottom: 23px;
		}
		.download-app {
			margin-top: 22px;
		}
	}
	.right {
		flex: 0 0 400;
		padding: 50px 26px 36px;
		position: relative;
		h3 {
			font: normal normal bold 20px/30px;
			margin-bottom: 37px;
		}
		input {
			font-size: 16px;
		}
		.input-container + .input-container {
			margin-top: 26px;
		}
		.forgot-password-link {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 20px;
			margin-bottom: 5px;
			font-size: 12px;
			white-space: nowrap;
			button {
				border: none;
				background-color: transparent;
				font: normal normal medium 12px;
				color: #00baf2;
				cursor: pointer;
			}
		}
		.login-button,
		.register-button {
			width: 100%;
			height: 50px;
			margin-top: 20px;
			font: normal normal bold 18px/27px;
			text-align: center;
			cursor: pointer;
		}
		.login-button {
			background: var(--login-btn-bg);
			color: var(--login-btn-color);
			border: none;
		}
		.register-button {
			border: 1px solid #333;
			background-color: #fff;
			color: #333;
		}
	}
	.app-store {
		gap: 10px;

		img {
			display: block;
			width: 50%;
		}
	}
	@media only screen and (max-width: 767px) {
		max-width: 400px;
		min-height: calc(280px);
		margin: auto;
		.login-wrapper {
			max-width: 400px;
			& > div {
				width: 100%;
				margin: auto;
			}
		}
		.left {
			display: none;
		}
		.right .forgot-password-link {
			display: block;
			&button {
				display: block;
			}
		}
	}
`;
