import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ProtectedRoute = ({ Component }) => {
	const navigate = useNavigate();

	useEffect(() => {
		let token = sessionStorage.getItem('ID Token');

		if (isEmpty(token)) {
			navigate('/dashboard');
		}
	});

	return <Component />;
};

export default ProtectedRoute;
