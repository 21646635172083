import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import GlobalStyle from './style';
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
	<BrowserRouter>
		<App />
		<GlobalStyle />
	</BrowserRouter>,

	document.getElementById('root')
);

reportWebVitals();
