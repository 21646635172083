import styled from 'styled-components';

export const CustomDropdownWrapper = styled.div`
	position: relative;

	input {
		padding: 8px;
		width: 300px;
	}

	.dropdown {
		position: absolute;
		top: 100%;
		left: 0px;
		width: 400px;
		max-height: 300px;
		overflow-y: auto;
		background-color: white;
		border: 1px solid #ccc;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
		border-radius: 4px 0 0 0;
		z-index: 1;
		padding: 8px;
	}

	.dropdown-option {
		padding: 2px 8px;
		cursor: pointer;
		display: flex;
		align-items: center;
		gap: 10px;

		img {
			width: 50px;
		}
		&.active {
			background-color: #00baf2;
			color: #fff;
		}
	}

	.dropdown-option:hover {
		background-color: #f0f0f0;
	}
	@media only screen and (max-width: 620px) {
		.dropdown {
			width: 320px;
		}
	}

	@media only screen and (max-width: 480px) {
		.dropdown {
			width: calc(100% + 40px);
			left: -20px;
		}
	}
`;
