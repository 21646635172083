import styled from 'styled-components';
import { TabContentWrapper } from '../UI/styledConstants';

export const Wrapper = styled(TabContentWrapper)`
	.payee-actions {
		button {
			min-width: 80px;
		}
	}
`;
