import React from "react";

export default function IconBroadband({ color = '#fff',color_2="#a9a1a1"}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44.474"
      height="38.402"
      viewBox="0 0 44.474 38.402"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            fill="none"
            d="M0 0H44.474V38.402H0z"
            data-name="Rectangle 123"
          ></path>
        </clipPath>
      </defs>
      <g data-name="Group 92">
        <g fill="none" clipPath="url(#clip-path)" data-name="Group 91">
          <path
            stroke={color_2}
            strokeLinecap="round"
            strokeWidth="1.5"
            d="M15.346 16.768a9.274 9.274 0 0114.07-.391"
            data-name="Path 251"
          ></path>
          <path
            stroke={color_2}
            strokeLinecap="round"
            strokeWidth="1.5"
            d="M11.471 12.431a14.383 14.383 0 0121.819-.605"
            data-name="Path 252"
          ></path>
          <path
            stroke={color_2}
            strokeLinecap="round"
            strokeWidth="1.5"
            d="M7.512 8.11a19.6 19.6 0 0129.738-.825"
            data-name="Path 253"
          ></path>
          <path
            d="M39.223 13.33L30.28 27.048a1.926 1.926 0 01-2.665.562l-.066-.044a1.926 1.926 0 01-.562-2.664l8.944-13.718a1.924 1.924 0 012.664-.562l.066.044a1.925 1.925 0 01.562 2.664"
            data-name="Path 254"
            transform="translate(3.903 1.508)"
          ></path>
          <path
            d="M1.256 13.33L10.2 27.048a1.926 1.926 0 002.665.562l.066-.044a1.926 1.926 0 00.562-2.664L4.548 11.184a1.924 1.924 0 00-2.664-.562l-.066.044a1.925 1.925 0 00-.562 2.664"
            data-name="Path 256"
            transform="translate(.138 1.508)"
          ></path>
          <path
            className="path-stroke"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="1.5"
            d="M12.098 23.642H7.406L1.394 14.42a1.925 1.925 0 01.562-2.665l.066-.043a1.923 1.923 0 012.664.562l7.411 11.367z"
            data-name="Subtraction 1"
          ></path>
          <path
            className="path-stroke"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="1.5"
            d="M32.323 23.642h4.692l6.012-9.222a1.925 1.925 0 00-.562-2.664l-.066-.043a1.923 1.923 0 00-2.664.562l-7.411 11.367z"
            data-name="Subtraction 2"
          ></path>
          <path
            stroke="#00286f"
            strokeWidth="1"
            d="M36.664 37.542H7.81a6.95 6.95 0 110-13.9h28.854a6.95 6.95 0 110 13.9"
            data-name="Path 258"
          ></path>
          <path
            className="path-stroke"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="1.5"
            d="M36.664 37.542H7.81a6.95 6.95 0 110-13.9h28.854a6.95 6.95 0 110 13.9z"
            data-name="Path 259"
          ></path>
          <path
            d="M14.931 29.173H6.838a2.161 2.161 0 01-2.161-2.16v-.089a2.161 2.161 0 012.161-2.16h8.093a2.161 2.161 0 012.161 2.16v.089a2.161 2.161 0 01-2.161 2.16"
            data-name="Path 260"
            transform="translate(.684 3.624)"
          ></path>
          <rect
            width="12.415"
            height="4.41"
            className="path-stroke"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="1.5"
            data-name="Rectangle 122"
            rx="1.885"
            transform="translate(5.361 28.387)"
          ></rect>
          <path
            d="M25.017 26.837a1.177 1.177 0 11-1.177-1.177 1.178 1.178 0 011.177 1.177"
            data-name="Path 261"
            transform="translate(3.316 3.755)"
          ></path>
          <ellipse
            cx="1.177"
            cy="1.177"
            stroke={color_2}
            strokeLinecap="round"
            strokeWidth="1.5"
            data-name="Ellipse 48"
            rx="1.177"
            ry="1.177"
            transform="translate(25.978 29.415)"
          ></ellipse>
          <path
            d="M29.525 26.837a1.177 1.177 0 11-1.177-1.177 1.178 1.178 0 011.177 1.177"
            data-name="Path 262"
            transform="translate(3.976 3.755)"
          ></path>
          <ellipse
            cx="1.177"
            cy="1.177"
            stroke={color_2}
            strokeLinecap="round"
            strokeWidth="1.5"
            data-name="Ellipse 49"
            rx="1.177"
            ry="1.177"
            transform="translate(31.146 29.415)"
          ></ellipse>
          <path
            d="M34.034 26.837a1.177 1.177 0 11-1.177-1.177 1.178 1.178 0 011.177 1.177"
            data-name="Path 263"
            transform="translate(4.636 3.755)"
          ></path>
          <ellipse
            cx="1.177"
            cy="1.177"
            stroke={color_2}
            strokeLinecap="round"
            strokeWidth="1.5"
            data-name="Ellipse 50"
            rx="1.177"
            ry="1.177"
            transform="translate(36.314 29.415)"
          ></ellipse>
        </g>
      </g>
    </svg>
  );
}
