import React from 'react';
import './PrivacyPolicy.css';

function PrivacyPolicy() {
	return (
		<div className="content-wrapper">
			<div className="heading">
				<h2 className="m-auto">Privacy Policy</h2>
			</div>
			<div className="contentwrapper">
				<div className="container">
					<div className="top conditions-heading">
						<h4 className="heading-4">Privacy Policy</h4>
					</div>
					<div className="top">
						<div className="condition-wrapper">
							<div className="conditions">
								<p className="headings mb20">This Privacy Policy outlines how we collects, uses, and protects the personal information of users of our payment gateway services.</p>
							</div>
						</div>
					</div>
					<div className="top conditions-heading">
						<h4 className="heading-4">Information Collection</h4>
					</div>
					<div className="top">
						<div className="condition-wrapper">
							<div className="conditions">
								<ul className="headings mb20 ">
									<li className="disc-list">
										When you use our payment gateway, we may collect personal information, including but not limited to your name, contact information, payment details, and
										transaction history.
									</li>
									<li className="disc-list">
										We may collect information automatically, such as IP addresses, browser type, and device information, to enhance the security and functionality of our services.
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="top conditions-heading">
						<h4 className="heading-4 "> How We Use Your Information</h4>
					</div>
					<div className="top">
						<div className="condition-wrapper">
							<div className="conditions">
								<ul className="headings mb20 ">
									<li className="disc-list">We use your information to process payments, facilitate transactions, and provide customer support.</li>
									<li className="disc-list">Your data is used to enhance the security of our payment gateway and protect against fraud or unauthorized access.</li>
									<li className="disc-list">We may use your contact information to send important updates, notifications, and promotional materials related to our services.</li>
								</ul>
							</div>
						</div>
					</div>

					<div className="top conditions-heading">
						<h4 className="heading-4 ">Data Security</h4>
					</div>
					<div className="top">
						<div className="condition-wrapper">
							<div className="conditions">
								<ul className="headings mb20 ">
									<li className="disc-list">
										We implement industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction.
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default PrivacyPolicy;
