
import React, {useContext } from 'react';
import './style.css';
import { GlobleContaxt } from '../../../Context/GlobleContaxt';

export default function UserProfile() {


	const {userDetails} = useContext(GlobleContaxt);

	

	return (
		<div>
			
			<h2 className="user-first">
				<span className="user-welcome">Welcome, </span>
				<span className="user-name">
					{userDetails?.firstName} {userDetails?.lastName}
				</span>
			</h2>
			<div className="user-wrapper user-wrapper-profile flex justify-center">
				<div className="personal-information personal-daital flex ">
					<div>
					<form>
						<h3>Personal information</h3>
						<div className="user-detail">
							<div className="field-wrapper">
								<div className="form-field">
									<label className="detail-key">First Name</label>
									<input type="text" name="firstName" className="detail-value" value={userDetails?.firstName} />
								</div>
								<div className="form-field">
									<label className="detail-key">Last Name</label>
									<input type="text" name="lastName" className="detail-value" value={userDetails?.lastName} />
								</div>
							</div>
							<div className="field-wrapper">
								<div className="form-field">
									<label className="detail-key">Mobile No.</label>
									<input type="number" name="userName" className="detail-value" value={userDetails?.userName} />
								</div>
								<div className="form-field">
									<label className="detail-key">Email Address</label>
									<input type="email" name="email" className="detail-value" value={userDetails?.email} />
								</div>
							</div>
							<div className="field-wrapper">
								<div className="form-field">
									<label className="detail-key">Date of Birth</label>
									<input type="text" name="dob" className="detail-value" value={userDetails?.dob} />
								</div>
							</div>
						</div>
						<h3>Address</h3>
						<div className="user-detail">
							<div className="field-wrapper">
								<div className="form-field">
									<label className="detail-key">Address 1</label>
									<input type="text" name="address1" className="detail-value" value={userDetails?.address1} />
								</div>
								<div className="form-field">
									<label className="detail-key">Address 2</label>
									<input type="text" name="address2" className="detail-value" value={userDetails?.address2} />
								</div>
							</div>
							<div className="field-wrapper">
								<div className="form-field">
									<label className="detail-key">Pin Code</label>
									<input type="text" name="pincode" className="detail-value" value={userDetails?.pincode} />
								</div>
								<div className="form-field">
									<label className="detail-key">Landmark</label>
									<input type="text" name="landmark" className="detail-value" value={userDetails?.landmark} />
								</div>
							</div>
						</div>
					</form>
					</div>
				</div>
			</div>
			
			
		</div>
	);
}
 