import React from "react";
import { LoaderWrapper } from "../styledConstants";
import logo  from '../../../assets/images/favicon.jpeg'


export default function Loader(){

    return(
        <LoaderWrapper>
            <div className="Loader">
                {/* <img src={logo} alt="logo" /> */}
                <div></div><div></div><div></div><div></div>
            </div>
        </LoaderWrapper>
    )
}