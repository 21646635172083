import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Wrapper } from './style';
import IconClose from '../../assets/images/IconClose';

export default function Modal({ children = null, status = false, handleModal = () => {}, ...rest }) {
	const [domReady, setDomReady] = React.useState(false);

	useEffect(() => {
		setDomReady(true);
	}, []);

	if (!status) {
		document.body.classList.remove('hasModal');
		return null;
	}

	return domReady && status
		? ReactDOM.createPortal(
				<Wrapper {...rest}>
					<div className="modal">
						<button className="modal-close" onClick={() => handleModal(false)}>
							<IconClose />
						</button>
						{children}
					</div>
				</Wrapper>,
				document.getElementById('portal')
		  )
		: null;
}
