import styled from 'styled-components';

export const Wrapper = styled.div`
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgba(200, 200, 200, 0.8);
	opacity: 1;
	z-index: 10000;
	backdrop-filter: blur(12px);
	-webkit-backdrop-filter: blur(12px);
	.modal {
		position: absolute;
		max-width: ${({ width }) => (width ? `${width}` : '600px')};
		min-width: 280px;
		height: auto;
		top: 50px;
		left: 50%;
		transform: translate(-50%, 0);
		background: #fff;
		z-index: 2;
		box-shadow: 0px 3px 82px #999;
	}
	.modal-close {
		width: 20px;
		height: 20px;
		display: flex;
		position: absolute;
		align-items: center;
		justify-content: center;
		right: 20px;
		top: 20px;
		color: #333;
		border: none;
		background-color: transparent;
		cursor: pointer;
		z-index: 1;
	}
`;
