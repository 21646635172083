import { Wrapper } from './style';
import airtel from '../../assets/images/airtel.svg';
import jio from '../../assets/images/jio.svg';
import vi from '../../assets/images/vi.svg';
import bsnl from '../../assets/images/bsnl.svg';

function SelectOprater() {
	return (
		<Wrapper>
			<div>
				<img src={airtel} alt='img'></img>
			</div>
			<div>
				<img src={jio} alt='img'></img>
			</div>
			<div>
				<img src={vi} alt='img'></img>
			</div>
			<div>
				<img src={bsnl} alt='img'></img>
			</div>
		</Wrapper>
	);
}

export default SelectOprater;
