import React from 'react';
import './loader.css';

const LocalLoader = () => {
	return (
		<>
			<div className="wrapper-loader">
				<div className="loader"></div>
			</div>
		</>
	);
};
export default LocalLoader;
