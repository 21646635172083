import React from 'react';

export default function IconWater({ color = '#fff',color_2="#a9a1a1",fillColor="nonr" }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="37.605" height="39.514" viewBox="0 0 37.605 39.514">
			<g id="Group_286" data-name="Group 286" transform="translate(0.75 1.022)">
				<path
					id="Subtraction_4"
					data-name="Subtraction 4"
					d="M13.7,37.742A12.863,12.863,0,0,1,4.013,33.2,16.51,16.51,0,0,1,0,22.225a16.155,16.155,0,0,1,2.141-7.088A50.23,50.23,0,0,1,6.852,7.9,88.716,88.716,0,0,1,13.7,0l.012.01A89.034,89.034,0,0,1,20.558,7.9a50.239,50.239,0,0,1,4.712,7.238,16.154,16.154,0,0,1,2.142,7.088c0,.238,0,.481-.014.721-1.6,2.137-3.513,5.16-3.513,7.428a8.835,8.835,0,0,0,.219,1.961A13.1,13.1,0,0,1,13.7,37.742Z"
					transform="translate(0 0)"
					fill="none"
                    stroke={color}
					strokeLinecap="round"
					strokeWidth="1.5"
				/>
				<path
					id="Path_550"
					data-name="Path 550"
					d="M44.618,41.754c0,4.07-2.736,7.368-6.11,7.368s-6.109-3.3-6.109-7.368S38.508,31.2,38.508,31.2s6.11,6.484,6.11,10.554"
					transform="translate(-8.513 -11.381)"
					fill={fillColor}
                    stroke={color_2}
					strokeLinecap="round"
					strokeWidth="1.5"
				/>
			</g>
		</svg>
	);
}
