import React from 'react';
import { ModalWraper } from '../UI/styledConstants';
import IconClose from '../../assets/images/IconClose';
//import IconSuccess from '../UI/AnimatedComponent/IconSuccess';
import logoBlack from '../../assets/images/logoBlack.svg';
import backgroundImg from '../../assets/images/bg-img.png';
import { downloadDivAsImage, numberToWords } from '../../utils/common';
import { isEmpty } from 'lodash';

const CreditCardReceipt = ({ paymentDaital, setPaymentDaital }) => {
	const handleDownloadClick = (title) => {
		const divElement = document.getElementById('model');
		downloadDivAsImage(divElement, title);
	};
	return (
		<ModalWraper url={backgroundImg}>
			<div className="modal modal-success" id="model">
				<div className="flex modal-header justify-between">
					<div className="logo">
						<img src={logoBlack} alt={'logo'} />
						<div>
							<p>Invoicing and payments</p>
							<p>powered by TechFora </p>
						</div>
					</div>

					<div className="buttons">
						<button className="button-solid" style={{ marginRight: '8px' }} onClick={() => handleDownloadClick('Cridit Card receipt')}>
							Download
						</button>
						<button className="button-solid" onClick={() => window.print()}>
							Print
						</button>
					</div>
				</div>
				<div className="modal-close" onClick={() => setPaymentDaital({})}>
					<IconClose />
				</div>
				<div className="text-center modal-title">
					<h2 className="transactionMsg">Transaction Successful</h2>
					<h3 className="amount">₹{paymentDaital?.txnValue}</h3>
					<p className="amount-text">{numberToWords(paymentDaital?.txnValue)} rupee only</p>
				</div>
				<div className="modal-body">
					<div className="txn-daital">
						<div className="flex justify-between txn-daital-h">
							<div>Send To</div>
						</div>
						<div className="flex justify-between txn-desc">
							<div className="txn-desc-right">
								{!isEmpty(paymentDaital?.payee?.name) && (
									<div>
										<span className="heading">Name</span>
										<span>{paymentDaital?.payee?.name}</span>
									</div>
								)}
								{!isEmpty(paymentDaital?.payee?.bankName) && (
									<div>
										<span className="heading">Bank Name</span>
										<span>{paymentDaital?.payee?.bankName}</span>
									</div>
								)}
								<div>
									<span className="heading">Account No.</span>
									<span>{paymentDaital?.payee?.account}</span>
								</div>
								<div>
									<span className="heading">Card Type</span>
									<span>{paymentDaital?.payee?.cardNetwork}</span>
								</div>
							</div>
							<div className="txn-desc-left">
								<div>
									<span>Order ID: </span>
									<span>{paymentDaital?.txnReferenceId}</span>
								</div>
								<div>
									<span>UTR No.: </span>
									<span>{paymentDaital?.poolReferenceId}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</ModalWraper>
	);
};

export default CreditCardReceipt;
