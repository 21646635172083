import React, { useEffect, useState } from 'react';
import { AlertWrapper, ModalWraper } from '../UI/styledConstants';
import IconClose from '../../assets/images/IconClose';

import { isEmpty } from '../../utils/common';
import Loader from '../UI/Loader/Loader';

export default function OtpModal({ modalData = null, onCloseModal = () => {}, onSubmitOtp = () => {}, setOtpMsg, otpMsg, lodingDelete = false, resendOtp = () => {} }) {
	const [otp, setOtp] = useState('');
	const [seconds, setSeconds] = useState(30);
	const numberReg = /^[0-9]+$/;
	const onchangeHandeler = (e) => {
		if (!numberReg.test(e.target.value) && e.target.value) {
			return false;
		}
		setOtp(e.target.value);
	};

	useEffect(() => {
		const timer = setInterval(() => {
			if (seconds === 0) {
				clearInterval(timer);
			} else {
				setSeconds(seconds - 1);
			}
		}, 1000);

		return () => {
			clearInterval(timer);
		};
	}, [seconds]);

	const resendOtpHandeler = () => {
		setOtpMsg('');
		resendOtp('resend');
	};

	const onsubmitHandaler = () => {
		setSeconds(0);
		onSubmitOtp(otp);
	};

	useEffect(() => {
		if (lodingDelete === false && isEmpty(otpMsg)) {
			setSeconds(30);
		}
	}, [lodingDelete]);

	return (
		<ModalWraper width="300px">
			<div className="modal otp-modal">
				<div className="modal-header">
					<div className="modal-title">Verify OTP</div>
					<div className="modal-close" onClick={onCloseModal}>
						<IconClose />
					</div>
				</div>
				<div className="modal-body">
					<div className="otp-modal-form">
						{!isEmpty(modalData?.success) && <div className={`${modalData?.success ? 'success' : 'error'} page-msg`}>{modalData?.msg}</div>}
						{!isEmpty(otpMsg) && (
							<AlertWrapper className="error">
								<span className="close" onClick={() => setOtpMsg('')}>
									×
								</span>
								{otpMsg}
							</AlertWrapper>
						)}

						<div className="row">
							<div className="col-12">
								<div className="form-field">
									<input type="text" name="otp" maxLength={6} placeholder="Enter OTP" value={otp} onChange={onchangeHandeler} />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="resent-otp">
					{seconds > 0 ? (
						<span style={{ color: '#00BAF2' }}>Resend Otp After: {seconds}</span>
					) : (
						<span style={{ color: 'green', cursor: 'pointer' }} onClick={() => resendOtpHandeler()}>
							Resent Otp
						</span>
					)}
				</div>
				<div className="modal-footer">
					<button className="button-solid" onClick={() => onsubmitHandaler()}>
						SUBMIT
					</button>
				</div>
				{lodingDelete && <Loader />}
			</div>
		</ModalWraper>
	);
}
