import { useState, useEffect } from 'react';
import { isEmpty } from '../../utils/common';
import { AlertWrapper, TabContentWrapper } from '../UI/styledConstants';
import Loader from '../UI/Loader/Loader';
import { creditCardPayBill } from '../../utils/api';
import CreditCardReceipt from './CreditCardReceipt';
import OfferBanner from '../OfferBanner';

export default function Component({ className = '' }) {
	const [paymentDaital, setPaymentDaital] = useState({});
	const [message, setMessage] = useState({});
	const [formDetails, setFormDetails] = useState({
		payeeAccountNumber: '',
		nameOnCard: '',
		amount: '',
		remarks: '',
	});
	const [loading, setLoading] = useState(false);

	const numberReg = /^[0-9]+$/;
	const nameReg = /^[A-Za-z\s]+$/i;

	const onChangeHandler = (event) => {
		if (isEmpty(sessionStorage.getItem('ID Token'))) {
			setMessage({ type: 'error', msg: 'Please login first to continue', place: 'globle' });
			return;
		}

		if (event.target.name === 'payeeAccountNumber' || event.target.name === 'amount') {
			
			if (!numberReg.test(event.target.value) && event.target.value) {
				return false;
			}
		}
		if (event.target.name === 'nameOnCard') {
			
			if (!nameReg.test(event.target.value) && event.target.value) {
				return false;
			}
		}

		setFormDetails({
			...formDetails,
			[event.target.name]: event.target.value,
		});
	};

	const submitHandler = () => {
		if (isEmpty(formDetails.payeeAccountNumber) || (formDetails.payeeAccountNumber.length !== 16) ) {
			setMessage({ type: 'error', msg: 'Please enter valid card number', place: 'form' });
			return;
		} else if (isEmpty(formDetails.nameOnCard)) {
			setMessage({ type: 'error', msg: 'Please enter card holder name', place: 'form' });
			return;
		} else if (isEmpty(formDetails.amount)) {
			setMessage({ type: 'error', msg: 'Please enter amount', place: 'form' });
			return;
		} else if (isEmpty(formDetails.remarks)) {
			setMessage({ type: 'error', msg: 'Please enter remarks ', place: 'form' });
			return;
		}
		setLoading(true);

		//let latitude = '';
		//let longitude = '';

		// if (navigator.geolocation) {
		//     navigator.geolocation.getCurrentPosition((position) =>{
		//       latitude = position.coords.latitude;
		//       longitude = position.coords.longitude;
		//     });

		//   }

		const param = {
			payeeAccountNumber: formDetails.payeeAccountNumber,
			nameOnCard: formDetails.nameOnCard,
			amount: formDetails.amount,
			remarks: formDetails.remarks,
			latitude: '28.87879',
			longitude: '68.87879',
			mobile: '',
			txnId: '',
			merchantId: '',
		};
		creditCardPayBill(param).then((resp) => {
			setLoading(false);
			if (resp?.success) {
				setPaymentDaital(resp?.data);
				setFormDetails({
					payeeAccountNumber: '',
		            nameOnCard: '',
		            amount: '',
		            remarks: '',
				})
				//setMessage({ type:'success',msg: resp?.msg,place:"globle"});
			} else {
				if (resp?.errorCodeList?.length > 0) {
					setMessage({ type: 'error', msg: resp?.errorCodeList[0], place: 'globle' });
				} else {
					setMessage({ type: 'error', msg: resp?.msg, place: 'globle' });
				}
			}
		});
	};

	useEffect(() => {
		if (!isEmpty(message)) {
			setTimeout(() => {
				setMessage({});
			}, 5000);
		}
	}, [message]);

	return (
		
		<TabContentWrapper className={`tab-content-wrapper mobile-recharge-form`}>
			{!isEmpty(message) && message.place === 'globle' && (
				<AlertWrapper className={`${message.type} globle`}>
					<span className="close" onClick={() => setMessage({})}>
						×
					</span>
					{message.msg}
				</AlertWrapper>
			)}
			<div className="tab-content-left">
			<h2>Credit Card</h2>
				<div className="form-field">
					<input type="text" name="payeeAccountNumber" placeholder="Card Number" maxLength={16} value={formDetails.payeeAccountNumber} onChange={onChangeHandler} />
				</div>
				<div className="form-field">
					<input type="text" name="nameOnCard" placeholder="Card Holder Name" value={formDetails.nameOnCard} onChange={onChangeHandler} />
				</div>
				<div className="form-field">
					<div>
						<input type="text" name="amount" placeholder="Amount" value={formDetails.amount} onChange={onChangeHandler} />
						<div></div>
					</div>
				</div>
				<div className="form-field">
					<div className="form-field">
						<input type="text" name="remarks" placeholder="Remarks" value={formDetails.remarks} onChange={onChangeHandler} />
					</div>
				</div>
				{!isEmpty(message) && message.place === 'form' && (
					<AlertWrapper className={`${message.type}`}>
						<span className="close" onClick={() => setMessage({})}>
							×
						</span>
						{message.msg}
					</AlertWrapper>
				)}
				<button type="submit" className="button-outline" onClick={submitHandler}>
					Continue
				</button>
			</div>
			<div className="tab-content-right">{!loading && <OfferBanner />}</div>

			{!isEmpty(paymentDaital) && <CreditCardReceipt paymentDaital={paymentDaital} setPaymentDaital={setPaymentDaital} />}
			{loading && <Loader />}
		</TabContentWrapper>
	);
}
