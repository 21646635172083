import styled from 'styled-components';

export const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-around;
	background-color: #fff;
	padding: 20px 10px;
	box-shadow: 0 1px 1px #dfdfdf;
	gap: 5px;
	& > div {
		flex: 0 1 140px;
		img {
			width: 100%;
		}
	}
	h2 {
		font-weight: bold;
		font-size: 20px;
		line-height: 30px;
	}
`;
