import React, { useEffect, useState } from 'react';

import Input from '../../Components/UI/Input';
import { isEmpty } from 'lodash';
import { AlertWrapper } from '../UI/styledConstants';
import { handleResetNumberOtp, otpVerificationHandlerResetPassword } from '../../utils/api';
import Loader from '../UI/Loader/Loader';
import { InputWrapper } from '../UI/Input/style';

const OTPVerification = ({ phoneNumber, setWasOTPSent, setForgetPasswordClicked }) => {
	const [newDetails, setNewDetails] = useState({ otp: '', newPassword: '', confirmPassword: '' });
	const [message, setMessage] = useState({});

	const [loading, setLoading] = useState(false);
	const [seconds, setSeconds] = useState(30);
	const [showPassword, setShowPassword] = useState(false);

	const inputHandler = (event) => {
		setNewDetails({
			...newDetails,
			[event.target.name]: event.target.value,
		});

		// event.preventDefault();
	};

	const otpVerificationHandler = () => {
		if (isEmpty(newDetails?.newPassword)) {
			setMessage({ type: 'error', msg: 'New Password required!', place: 'local' });
			return;
		} else if ((newDetails?.newPassword).length <= 5) {
			setMessage({ type: 'error', msg: 'Minimun 6 characters are required in password  ', place: 'local' });
			return;
		} else if (newDetails?.newPassword !== newDetails?.confirmPassword) {
			setMessage({ type: 'error', msg: 'Confirm Password are not match ', place: 'local' });
		} else if (isEmpty(newDetails?.otp)) {
			setMessage({ type: 'error', msg: 'Please enter otp', place: 'local' });
			return;
		} else {
			const param = {
				key: newDetails.otp,
				newPassword: newDetails.newPassword,
				mobileNumber: phoneNumber,
				roleCode: 'ROLE_RETAILER',
			};
			setLoading(true);
			otpVerificationHandlerResetPassword(param).then((resp) => {
				setLoading(false);
				if (resp?.success) {
					setMessage({ type: 'success', msg: 'Password Reset successful', place: 'local' });
					setTimeout(() => {
						goToLogin();
					}, 5000);
				} else {
					if (resp.errorCodeList.length > 0) {
						setMessage({ type: 'error', msg: resp?.errorCodeList[0], place: 'local' });
					} else {
						setMessage({ type: 'error', msg: resp?.message, place: 'local' });
					}
				}
			});
		}
	};

	const goToLogin = () => {
		setForgetPasswordClicked(false);
		setWasOTPSent(false);
	};

	const resendOtp = () => {
		setLoading(true);
		const param = {
			mobileNumber: phoneNumber,
			roleCode: 'ROLE_RETAILER',
		};

		handleResetNumberOtp(param).then((resp) => {
			setLoading(false);
			if (resp.success) {
				setMessage({ type: 'success', msg: 'OTP send Successful', place: 'local' });
				setSeconds(30);
			} else {
				if (resp?.errorCodeList.length > 0) {
					setMessage({ type: 'error', msg: resp?.errorCodeList, place: 'local' });
				} else {
					setMessage({ type: 'error', msg: resp?.message, place: 'local' });
				}
			}
		});
	};

	useEffect(() => {
		const timer = setInterval(() => {
			if (seconds === 0) {
				clearInterval(timer);
			} else {
				setSeconds(seconds - 1);
			}
		}, 1000);

		return () => {
			clearInterval(timer);
		};
	}, [seconds]);
	return (
		<div>
			<div className="right">
				<h3>Change Password</h3>

				{!isEmpty(message) && message.place == 'local' && (
					<AlertWrapper className={`${message.type} local`}>
						<span className="close" onClick={() => setMessage({})}>
							×
						</span>
						{message.msg}
					</AlertWrapper>
				)}

				<InputWrapper className="input-container">
					<div className="i-icon" onClick={() => setShowPassword(!showPassword)}>
						{showPassword ? <i class="fa fa-eye" aria-hidden="true"></i> : <i class="fa fa-eye-slash" aria-hidden="true"></i>}
					</div>
					<input className="input-text" type={showPassword ? 'text' : 'password'} onChange={inputHandler} name="newPassword" value={newDetails.newPassword} maxLength={20} />
					<label className="placeholder-text">
						<span className="text">New Password</span>
					</label>
				</InputWrapper>
				
				<Input
					elemConfig={{
						type: 'password',
						name: 'confirmPassword',
						placeholder: 'Confirm Password',
						value: newDetails.confirmPassword,
						maxLength: '20',
					}}
					onChange={inputHandler}
				/>
				<Input
					elemConfig={{
						type: 'text',
						name: 'otp',
						placeholder: 'OTP',
						maxLength: '6',
						value: newDetails.otp,
					}}
					onChange={inputHandler}
				/>
				<div className="forgot-password-link" style={{ margin: '6px 0px' }}>
					{seconds > 0 ? (
						<button style={{ color: '#00baf2' }}> Resend after :{seconds}</button>
					) : (
						<button style={{ color: 'green' }} onClick={resendOtp}>
							{' '}
							Resend Otp
						</button>
					)}

					<button onClick={goToLogin}>Back To Login</button>
				</div>

				<button className="login-button" onClick={otpVerificationHandler}>
					Submit OTP
				</button>
			</div>

			{loading && <Loader />}
		</div>
	);
};

export default OTPVerification;
