import React from "react";

export default function IconBillRecharge({ color = '#fff', color_2="#a9a1a1",fillColor="none"}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="40.482"
      viewBox="0 0 24 40.482"
    >
      <g data-name="Group 303" transform="translate(-308.995 -143.518)">
        <g fill={fillColor} strokeLinecap="square" data-name="Path 548">
          <path
            d="M-3895.455 3055.733l22.455-20.885-6.91 15.284h6.91l-17.994 25.2 5.471-19.6z"
            transform="translate(4204.45 -2891.33)"
          ></path>
          <path
            className="path-color"
            fill={color}
            d="M-3887.254 3067.511l11.34-15.88h-6.32l4.795-10.606-14.2 13.208h8.092l-3.707 13.278m-3.74 7.819l5.47-19.597h-9.931l22.455-20.885-6.91 15.283h6.91l-17.994 25.199z"
            transform="translate(4204.45 -2891.33)"
          ></path>
        </g>
        <g data-name="Group 302" transform="translate(171.995 -467.241)">
          <path
            fill={color_2}      
            d="M-2767.886-4106.182l16.806-23.534h2.087zm4.655-18.091l-6.486-.6h6.654l-.167.6zm-9.467-.877h-.052l4.329-3.973-4.276 3.978zm17.042-5.325h-1.795l6.612-14.624-4.817 14.623z"
            data-name="Subtraction 5"
            transform="translate(2909.995 4756.518)"
          ></path>
        </g>
      </g>
    </svg>
  );
}
