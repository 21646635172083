import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import "./App.css";
import Homepage from './Pages/Homepage';
import TransactionHistory from './Components/Header/TransactionHistory';
import UserProfile from './Components/Login/UserProfile/UserProfile';
import WalletHistory from './Components/Header/WalletHistory';
import Login from './Components/Login';
import Modal from './Components/Modal';
import Header from './Components/Header';
import Footer from './Components/Footer';
import ScrollTop from './Components/ScrollTop/ScrollTop';

import Consumer from './Pages/Consumer/Consumer';
import Business from './Pages/Business/Business';
import Company from './Pages/Company/Company';
import { GlobleContaxt } from './Context/GlobleContaxt';
import ProtectedRoute from './Pages/ProtectedRoute';
import TermsandConditions from './Pages/TermsandConditions/TermsandConditions';
import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy';
import Contactus from './Pages/ContactUs/Contactus';
import Faq from './Pages/FAQ/Faq';
import Services from './Pages/Services/Services';

function App() {
	const [modalConfig, setModalConfig] = useState({ status: false, config: null });
	const [userDetails, setUserDetails] = useState({});
	const [apiPermission, setApiPermission] = useState([]);

	const handleModal = (status = false) => {
		setModalConfig({ ...modalConfig, status });
	};

	const globleState = { userDetails, setUserDetails, setModalConfig, setApiPermission, apiPermission };

	return (
		<GlobleContaxt.Provider value={{ ...globleState }}>
			<Header handleModal={handleModal} />
			<ScrollTop />
			<Routes>
			    <Route path="/*" element={<Homepage />} />
				<Route path="/transaction-history" element={<ProtectedRoute Component={TransactionHistory} />} />
				<Route path="/wallet-history" element={<ProtectedRoute Component={WalletHistory} />} />
				<Route path="/user-profile" element={<ProtectedRoute Component={UserProfile} />} />
				<Route path="/business" element={<Business />} />
				<Route path="/consumer" element={<Consumer />} />
				<Route path="/terms-conditions" element={<TermsandConditions />} />
				<Route path="/privacy-policy" element={<PrivacyPolicy />} />
				<Route path="/company" element={<Company />} />
				<Route path="/contactus" element={<Contactus />} />
				<Route path="/faq" element={<Faq />} />
				<Route path="/services" element={<Services />} />
				<Route path="/dashboard" element={<Homepage />} />
			</Routes>

			<Footer />
			<Modal width="740px" handleModal={handleModal} status={modalConfig.status}>
				<Login />
			</Modal>
		</GlobleContaxt.Provider>
	);
}

export default App;
