import React from "react";

export default function IconExploreMore({color="#a9a1a1",color_2="#a9a1a1"}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="8"
      viewBox="0 0 32 8"
    >
      <g fill={color} data-name="Group 454" transform="translate(-1221 -166)">
        <circle
          cx="4"
          cy="4"
          r="4"
          data-name="Ellipse 83"
          transform="translate(1221 166)"
        ></circle>
        <circle
          cx="4"
          cy="4"
          r="4"
          data-name="Ellipse 84"
          transform="translate(1233 166)"
        ></circle>
        <circle
          cx="4"
          cy="4"
          r="4"
          data-name="Ellipse 85"
          transform="translate(1245 166)"
        ></circle>
      </g>
    </svg>
  );
}