import React from "react";
import IconAddmoney from "../../assets/images/IconAddmoney";
import IconAEPS from '../../assets/images/IconAEPS';
import IconBillRecharge from "../../assets/images/IconBillRecharge";
import IconBroadband from "../../assets/images/IconBroadband";
import IconDTH from "../../assets/images/IconDTH";
import IconExploreMore from "../../assets/images/IconExploreMore";
import IconMobileRecharge from "../../assets/images/IconMobileRecharge";
import IconSendmoney from "../../assets/images/IconSendmoney";
import IconCredit from '../../assets/images/IconCredit';
import IconWater from "../../assets/images/IconWater";
import IconPipeGash from "../../assets/images/IconPipeGash";
import IconLandLine from "../../assets/images/IconLandLine";

const iconMap = {
  //IconAEPS: <IconAEPS color={"#e3871e"} />,
  //IconMobileRecharge: <IconMobileRecharge color={"#613D3B"} color_2={"#f7ec31"} fillColor={"#613D3B"} />,
  IconBillRecharge: <IconBillRecharge color={"#D44100"} color_2={"#D44100"} fillColor={"#5D1C46"} />,
  IconBroadband: <IconBroadband color={"#D44100"} color_2={"#D44100"} />,
  //IconDTH: <IconDTH color={"#613D3B"} />,
  //IconSendmoney: <IconSendmoney color={"#613D3B"} color_2={"#f7ec31"} />,
  //IconAddmoney: <IconAddmoney color={"#613D3B"} color_2={"#f7ec31"} />,
 // IconCreditCard: <IconCredit color={"#613D3B"} color_2={"#f7ec31"} />,
  IconWater: <IconWater color={"#D44100"} color_2={"transaprent"} fillColor="#D44100" />,
  IconExploreMore: <IconExploreMore color={"#D44100"} color_2={"#D44100"} />,
  IconPipeGash: <IconPipeGash color={"#D44100"} color_2={"#D44100"} fillColor={"#5D1C46"} />,
  IconLandLine: <IconLandLine color={"#D44100"} color_2={"#D44100"} fillColor={"#D44100"} />,
}

const TabNavItem = ({ id, title, isActive, setActiveTab, icon = "" }) => {
 
 
return (
   <div onClick={() =>setActiveTab(id)} className={isActive ? "nav-link active" : "nav-link"}>
    <div className="pic">
		{iconMap?.[icon]}
	</div>
		<span>{title}</span>
   </div>
 );
};
export default TabNavItem;