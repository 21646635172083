import styled from 'styled-components';

export const Wrapper = styled.div`
	//background-color: var(--section-gbColor-V2);
	padding: 50px 50px 0;
	margin-top: 20px;
	margin-bottom: 40px;
	
	overflow: visible;
	&>.container {
		padding-top: 100px;
		background: var(--section-gbColor-V2);
		border-radius: 10px;
		box-shadow: 0 1px 1px #dfdfdf;
		
	}
	.img {
		flex: 0 1 500px;
		img {
			margin-bottom: -90px;
		}
	}
	.about-text {
		flex: 0 1 600px;

		h2 {
			margin-bottom: 14px;
		}
		p {
			font-size: 20px;
			line-height: 35px;
			color: #fff;
		}
	}
	@media only screen and (max-width: 767px) {
		padding: 20px 20px 0;
		.container {
			flex-direction: column-reverse;

			& > div {
				margin: 0 auto 0;
				flex: 0 1 auto;
			}
		}
	}
`;
