import axios from 'axios';
class Request {
	constructor(dispatch, successFn, errorFn) {
		this.successFn = typeof successFn === 'function' ? successFn : () => {};
		this.errorFn = typeof errorFn === 'function' ? errorFn : () => {};
		this.dispatch = typeof dispatch === 'function' ? dispatch : () => {};
	}

	/**
	 * GET axios instance and do things that are common for every request
	 */
	instance() {
		// const headers = {};
		const headers = {
			Authorization: `Bearer ${sessionStorage.getItem('ID Token')}`,
		};

		// if (process.env.NODE_ENV === "development") {
		//   headers.frontEnv = "local";
		// }

		const instance = axios.create({
			baseURL: '',
			headers,
		});

		return instance;
	}

	/**
	 * GET Requests
	 * @param {string} url
	 * @param {object} params
	 */
	async get(url, params = {}) {
		try {
			const res = await this.instance().get(url, params);
			const data = res.data ? res.data : null;
			const headers = res.headers ? res.headers : null;
			this.successFn(data, headers);
			return data;
		} catch (error) {
			// const data = error.hasOwnProperty('data') ? data : null;
			// const headers = error.hasOwnProperty('headers') ? headers : null;

			this.errorFn(error);
		}
	}

	/**
	 * POST Requests
	 * @param {string} url
	 * @param {object} params
	 */
	async post(url, params = {}) {
		try {
			const res = await this.instance().post(url, params);
			const { data = {}, status } = res;
			this.successFn(data, status);
			return data;
		} catch (error) {
			this.errorFn(error);
		}
	}
	async delete(url, params = {}) {
		try {
			const res = await this.instance().post(url, params);
			const { data = {}, status } = res;
			this.successFn(data, status);
			return data;
		} catch (error) {
			this.errorFn(error);
		}
	}
}

export default Request;
