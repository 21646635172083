import  { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollTop = () => {
	const location = useLocation();

	const queryParams = new URLSearchParams(location.search);
	const paramValue = queryParams.get('type');

	const { pathname } = useLocation();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname, paramValue]);
	return null;
};

export default ScrollTop;
