import React from 'react';
import './TermsandCondition.css';

function TermsandConditions() {
	return (
		<div className="content-wrapper">
			<div className="heading">
				<h2 className="m-auto">Terms and Conditions</h2>
			</div>
			<div className="contentwrapper">
				<div className="container">
					<div className="top conditions-heading">
						<h4 className="heading-4 mb20 ">Introduction</h4>
					</div>
					<div className="top">
						<div className="condition-wrapper">
							<div className="conditions">
								<p className="headings mb20">
									By utilizing our payment gateway services, you agree to the following terms and conditions. You are responsible for maintaining the security of your account
									information and complying with all applicable laws and regulations. We reserve the right to refuse service, terminate accounts, or cancel transactions at our
									discretion. All fees and charges associated with our services are outlined on our website and are subject to change with notice. Refunds and cancellations are
									processed in accordance with our policies. We prioritize the security of your data, and you agree to promptly report any unauthorized access. By using our payment
									gateway, you acknowledge and accept these terms and conditions.
								</p>
							</div>
						</div>
					</div>
					<div className="top conditions-heading">
						<h4 className="heading-4 mb20 ">Payment Processing</h4>
					</div>
					<div className="top">
						<div className="condition-wrapper">
							<div className="conditions">
								<p className="headings mb20">
									Our payment gateway allows you to make and receive payments securely. By using our services, you agree to abide by the payment processing rules and guidelines
									outlined by us.
									<br />
									We reserve the right to refuse service, terminate accounts, or cancel transactions at our discretion.
								</p>
							</div>
						</div>
					</div>
					<div className="top conditions-heading">
						<h4 className="heading-4 mb20 ">Fees and Charges</h4>
					</div>
					<div className="top">
						<div className="condition-wrapper">
							<div className="conditions">
								<p className="headings mb20">
									You agree to pay all applicable fees and charges associated with our payment gateway services. Fee details are available on our website and may be subject to change
									with notice.
									<br />
									Failure to pay fees may result in the suspension or termination of your account..
								</p>
							</div>
						</div>
					</div>

					<div className="top conditions-heading">
						<h4 className="heading-4 mb20 ">Refunds and Cancellations</h4>
					</div>
					<div className="top">
						<div className="condition-wrapper">
							<div className="conditions">
								<p className="headings mb20">
									Refunds will be processed according to our refund policy, available on our website.
									<br />
									You may cancel transactions in accordance with our cancellation policy, as outlined on our website.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default TermsandConditions;
