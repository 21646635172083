import React from 'react';
import './Consumer.css';
import img from '../../assets/images/cus1.svg';
import img2 from '../../assets/images/cuse-2.svg';

const Consumer = () => {
	return (
		<div className="consumer_wrapper">
			<div className="heading">
				<h2 className="m-auto">CONSUMER</h2>
			</div>
			<div className="consumerWrapper">
				<div className="container">
					<div className="consumer-top flex justify-center gap-50 mb20">
						<div className="img">
							<img src={img} alt="img" />
						</div>
						<div className="desc self-center">
							<h2 className="heading-2 mb20 ">
								<span className="strong">Anytime, anywhere bill payment</span>
							</h2>
							<p>TechFora  is on a mission to provide a safe, transparent, instant, and convenient eWallet solution to banks and financial institutions across the globe.</p>
						</div>
					</div>
					<div className="consumer-top  consumer-middle flex justify-center gap-50 mb20">
						<div className="desc self-center">
							<h2 className="heading-2 mb20 ">
								<span className="strong">How to Pay a Bills?</span>
							</h2>
							<p>
								Confused on how to pay your electricity bills, telecom, insurance, Education, FASTag, Cable, Gas or DTH recharge? Pay easily, and faster through TechFora  enabled
								channels
							</p>
						</div>
						<div className="img">
							<img src={img2} alt="img" />
						</div>
					</div>
				</div>
				<div className="consumerBottom container">
					<div className="consumer-bottom flex justify-center gap-50 mb20">
						<div className="img">
							<img src={img2} alt="img" />
						</div>
						<div className="desc self-center">
							<h2 className="heading-2 mb20 ">
								<span className="strong">Android App</span>
							</h2>
							<p>Gone are the days when you could pay only one bill at a time. TechFora  offers all recurring payments at one destination.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Consumer;
