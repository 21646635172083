import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logo from '../assets/images/logoforpdf.jpeg';
export const getStorage = (key) => {
	try {
		const serializeState = sessionStorage.getItem(key);
		if (serializeState === null) {
			return undefined;
		}
		return JSON.parse(serializeState);
	} catch (error) {
		console.error('Err: Set Local Storage :', error);
	}
};

export const setStorage = (key, state) => {
	try {
		const serializeState = JSON.stringify(state);
		sessionStorage.setItem(key, serializeState);
	} catch (error) {
		console.error('Err: Get Local Storage:', error);
	}
};

export function getUser() {
	const userProfile = getStorage('userProfile');
	return userProfile;
}

export function hasProperty(obj, key) {
	return Object.prototype.hasOwnProperty.call(obj, key);
}

export function isEmpty(obj) {
	let isEmpty = false;
	const type = typeof obj;
	isEmpty = isEmpty || !obj;
	isEmpty = isEmpty || type === 'undefined'; // if it is undefined
	isEmpty = isEmpty || obj === null; // if it is null
	isEmpty = isEmpty || (type === 'string' && obj.trim() === ''); // if the string is empty or only have spaces
	isEmpty = isEmpty || obj === false || obj === 0; // if boolean value returns false
	isEmpty = isEmpty || (Array.isArray(obj) && obj.length === 0); // if array is empty
	isEmpty = isEmpty || (type === 'object' && Object.keys(obj).length === 0); // if object is empty
	return isEmpty;
}

export const getSelectOptions = (objList, id = 'id', name = 'name') => {
	const options = [];
	if (isEmpty(objList)) {
		return options;
	}
	if (Array.isArray(objList)) {
		objList.forEach((obj) => {
			const optValue = obj[id];
			const optName = obj[name];
			options.push({ label: optName, value: optValue });
		});
	} else {
		Object.keys(objList).forEach((key) => {
			const optValue = objList[key][id] || key;
			const optName = objList[key][name] || objList[key];
			options.push({ label: optName, value: optValue });
		});
	}

	return options;
};

// const number = /^[0-9]+$/;
// const name = /^[A-Za-z\s]+$/i;

export const userLogout = () => {};

export const removeMsg = (setMsg) => {
	setTimeout(() => {
		setMsg('');
	}, 3000);
};

export const numberToWords = (number) => {
	// Array of words for numbers from 0 to 19
	const words = [
		'Zero',
		'One',
		'Two',
		'Three',
		'four',
		'Five',
		'Six',
		'Seven',
		'Eight',
		'Nine',
		'Ten',
		'Eleven',
		'Twelve',
		'Thirteen',
		'Fourteen',
		'Fifteen',
		'Sixteen',
		'Seventeen',
		'Eighteen',
		'Nineteen',
	];

	// Array of tens words
	const tensWords = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', '(inety'];

	// Handle numbers from 0 to 99
	const convertToWords = (num) => {
		if (num < 20) {
			return words[parseInt(num)];
		} else {
			const tens = Math.floor(num / 10);
			const units = num % 10;

			if (units === 0) {
				return tensWords[tens];
			} else {
				return tensWords[tens] + ' ' + words[Math.floor(units)];
			}
		}
	};

	// Handle numbers above 99
	const convertNumber = (num) => {
		if (num === 0) {
			return 'Zero';
		}
		let words = '';
		if (num >= 10000000) {
			words += convertNumber(Math.floor(num / 10000000)) + ' Crore ';
			num %= 10000000;
		}
		if (num >= 100000) {
			words += convertNumber(Math.floor(num / 100000)) + ' Lakh ';
			num %= 100000;
		}
		if (num >= 1000) {
			words += convertNumber(Math.floor(num / 1000)) + ' Thousand ';
			num %= 1000;
		}
		if (num >= 100) {
			words += convertToWords(Math.floor(num / 100)) + ' Hundred ';
			num %= 100;
		}
		if (num > 0) {
			words += convertToWords(num);
		}
		return words.trim();
	};

	return convertNumber(number);
};

export const overLayers = (key) => {
	var body = document.getElementsByTagName('body');
	if (key) {
		body[0].style.overflowY = 'hidden';
	} else {
		body[0].style.overflowY = 'auto';
	}
};

export const isValidMobileNumber = (number) => {
	const regex = /^[0-9]{10}$/;
	return regex.test(number);
};

export const downloadDivAsImage = (element, title = 'downloaded_image', setCustonCss = () => {}, value = {}) => {
	html2canvas(element).then((canvas) => {
		// Convert canvas to base64 image data

		const dataUrl = canvas.toDataURL('image/png');
		saveAs(dataUrl, `${title}.png`);
		setCustonCss({ ...value });
		return;
	});
};

export function getCurrentDate(formate = '') {
	var date = new Date();
	var day = date.getDate();
	var month = date.getMonth() + 1;
	var year = date.getFullYear();

	if (day < 10) {
		day = '0' + day;
	}

	if (month < 10) {
		month = '0' + month;
	}

	if (formate === 'yyyy-mm-dd') {
		return year + '-' + month + '-' + day;
	} else {
		return `${day}-${month}-${year}`;
	}
}

export const pdfData = (data = [], header = [{}], text = 'list') => {
	const doc = new jsPDF();
	const imgWidth = 25;
	const imgHeight = 9;
	const leftXPos = 14;
	const yPos = 2;
	doc.addImage(logo, 'PNG', leftXPos, yPos, imgWidth, imgHeight);
	const formattedDate = getCurrentDate();

	const textWidth = (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) / doc.internal.scaleFactor;
	// Get the width of the PDF
	const pdfWidth = doc.internal.pageSize.width;
	const yPos1 = 10;
	// Calculate the X position for center alignment
	const centerXPos = (pdfWidth - textWidth) / 2;
	doc.text(text, centerXPos, yPos1);

	// Calculate the width of the date text
	const dateWidth = (doc.getStringUnitWidth(formattedDate) * doc.internal.getFontSize()) / doc.internal.scaleFactor;

	const rightXPos = pdfWidth - dateWidth - 15;
	doc.text(formattedDate, rightXPos, yPos1);

	// Create a table from API data

	if (!isEmpty(header)) {
		doc.autoTable({
			head: header,
			body: data,
		});
	} else {
		doc.autoTable({
			body: data,
		});
	}

	doc.save(`${text}.pdf`);
};
