import styled from 'styled-components';

export const Wrapper = styled.div`
	position: fixed;
	top: 100px;
	right: 0;
	right: 20px;
	z-index: 5;
	
	
	/* &:before {
		content: '';
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		width: 10px;
		height: 300px;
		border-radius: 50%;
		box-shadow: 20px 0 50px #707070;
	} */
	.inner-form {
		background-color: #fff;
		padding: 8px 0 18px;
		height: calc(100vh - 100px);
		overflow: auto;
		width: 300px;
		position: relative;
		border-right: 1px solid #e5e5e5;
		box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
		.subTab{
			display: flex;
			padding:  8px 26px;
			gap: 17px;
			img{
				width: 22px;
			}
			.subtab-title{
				font-weight: 600;
				font-size: 13px;
			}
			&:hover,
			&.active{
				color: var(--profile-dropdown_color_hover);
                background-color: var(--profile-dropdown_bg_hover);
				cursor: pointer;
			}
		}
	}
	.service-heading{
		font-size: 14px;
		padding: 0 26px;
		margin: 10px 0 4px;
		color: var(--heading_2-color)
	}
	
	@media only screen and (max-width: 1112px){
		top: 176px;
		
		.inner-form{
			height: calc(100vh - 176px);
		}
	}
	@media only screen and (max-width: 767px){
		top: 155px;
		
		.inner-form{
			height: calc(100vh - 155px);
		}
	}
`;
