import React, { useEffect, useState } from 'react';
import { AlertWrapper, ModalWraper } from '../UI/styledConstants';
import IconClose from '../../assets/images/IconClose';
import { addBeneficiaryRequest, getAepsBankList } from '../../utils/api';
import { isEmpty } from '../../utils/common';
import Loader from '../UI/Loader/Loader';

const initControls = {
	accountNumber: '',
	bankId: '',
	firstName: '',
	ifscCode: '',
	remid: '',
	merchantId: '',
};

export default function AddBeneficiaryModal({ modalData = null, mobileNumber = '', onCloseModal = () => {}, verifySenderStatus = () => {}, setModal }) {
	const [controls, setControls] = useState({ ...initControls });
	const [pageMsg, setPageMsg] = useState({});
	const [bankList, setBankList] = useState(null);
	const [filterBankList, setFilterBankList] = useState(null);
	const [dropDown, setDropdown] = useState(false);
	const [searchKey, setSearchKey] = useState('');
	const [loding, setLoding] = useState(false);

	const numberReg = /^[0-9]+$/;
	const nameReg = /^[A-Za-z\s]+$/i;

	const changeHandler = (e) => {
		const { name, value } = e.target;

		if (name === 'firstName') {
			if (!nameReg.test(value) && value) {
				return false;
			}
		}
		if (name === 'accountNumber') {
			if (!numberReg.test(value) && value) {
				return false;
			}
		}

		setControls({ ...controls, [name]: value });
		if (name === 'ifscCode') {
			setControls({ ...controls, [name]: value.toUpperCase() });
		}
		if (name == 'bankId') {
			setDropdown(false);
			setSearchKey(e.target?.nextElementSibling?.innerHTML);
		}
	};

	const onSubmit = () => {
		if (isEmpty(controls.firstName)) {
			setPageMsg({ type: 'error', text: 'Name are required.' });
			return;
		} else if (isEmpty(controls.accountNumber)) {
			setPageMsg({ type: 'error', text: 'Account number are required.' });
			return;
		} else if (isEmpty(controls.ifscCode)) {
			setPageMsg({ type: 'error', text: 'Ifsc code are required.' });
			return;
		} else if (isEmpty(controls.bankId)) {
			setPageMsg({ type: 'error', text: 'Bank name are required.' });
			return;
		}

		const params = {
			...controls,
			mobileNumber,
		};

		setControls({ ...initControls });
		setSearchKey('');
		setLoding(true);
		addBeneficiaryRequest(params).then((response) => {
			setLoding(false);
			if (response?.success) {
				setPageMsg({ type: 'success', text: response?.msg });
				setModal({ name: 'VERIFY_DDBENIFICILIA_OTP_MODAL', modalData: response, mobileNumber: mobileNumber });
			} else {
				setPageMsg({ type: 'error', text: response?.msg });
				if (response?.errorCodeList.length > 0) {
					setPageMsg({ type: 'error', text: response?.errorCodeList[0] });
				}
			}
		});
	};

	useEffect(() => {
		getAepsBankList().then((res) => {
			if (res?.data) {
				setBankList(res?.data);
			}
		});
	}, []);

	const handleFilter = (event) => {
		if (bankList) {
			const keyword = event.target.value;

			if (!nameReg.test(keyword) && keyword) {
				return false;
			}

			setSearchKey(keyword.toUpperCase());
			const pattern = new RegExp('^' + keyword, 'i');
			const filtered = bankList.filter((item, index) => {
				return pattern.test(item.bankName);
			});

			if (keyword) {
				setFilterBankList(filtered.slice(0, 4));
			} else {
				setFilterBankList(null);
			}
		}
	};

	return (
		<ModalWraper width="600px">
			<div className="modal pay-modal">
				<div className="modal-header">
					<div className="modal-title">Add Beneficiary</div>
					<div className="modal-close" onClick={onCloseModal}>
						<IconClose />
					</div>
				</div>
				<div className="modal-body">
					<div className="pay-modal-form">
						{!isEmpty(pageMsg) && (
							<AlertWrapper className={pageMsg?.type}>
								<span className="close" onClick={() => setPageMsg({})}>
									×
								</span>
								{pageMsg?.text}
							</AlertWrapper>
						)}
						<div className="row">
							<div className="col-6">
								<div className="form-field">
									<input type="text" name="firstName" placeholder="Beneficiary Name" value={controls?.firstName} onChange={(e) => changeHandler(e)} />
								</div>
							</div>
							<div className="col-6">
								<div className="form-field">
									<input type="text" name="accountNumber" placeholder="Account Number" value={controls?.accountNumber} onChange={(e) => changeHandler(e)} />
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-6">
								<div className="form-field">
									<input type="text" name="ifscCode" placeholder="IFSC Code" value={controls?.ifscCode} onChange={(e) => changeHandler(e)} />
								</div>
							</div>
							<div className="col-6" style={{ width: '50%' }}>
								<div className="form-field" style={{ position: 'relative' }}>
									<input type="text" name="amount" placeholder="Search Bank..." value={searchKey} onChange={handleFilter} onFocus={(e) => setDropdown(true)} />
									{dropDown && (
										<div className="radio-group bankList">
											{filterBankList &&
												filterBankList.map((val, index) => {
													return (
														<label style={{ display: 'block' }} key={index} className={controls.bank === val?.iinno ? 'radio active' : 'radio'}>
															<input type="radio" name="bankId" value={val?.id} onChange={(e) => changeHandler(e)} />
															<span className="icon-with-name">{val?.bankName}</span>
														</label>
													);
												})}
										</div>
									)}
								</div>
							</div>
							{/* <div className="col-6">
                                <div className="form-field searchBan">
                                    <input type="text" name="bankId" placeholder="Bank Name" value={controls?.bankId} onChange={(e) => changeHandler(e)} />
                                </div>
                            </div> */}
						</div>
					</div>
				</div>
				<div className="modal-footer">
					<button className="button-solid" onClick={onSubmit}>
						SUBMIT
					</button>
				</div>
				{loding && <Loader />}
			</div>
		</ModalWraper>
	);
}
