import React, { useState } from 'react';
import { AlertWrapper, ModalWraper } from '../UI/styledConstants';
import IconClose from '../../assets/images/IconClose';
import { sendMoneyRequest } from '../../utils/api';
import { isEmpty } from '../../utils/common';
import Loader from '../UI/Loader/Loader';

const transferOptions = [
	{
		label: 'IMPS',
		value: 'IMPS',
	},
	{
		label: 'NEFT',
		value: 'NEFT',
	},
	{
		label: 'RTGS',
		value: 'RTGS',
	},
];

export default function PayModal({
	modalData = null,
	mobileNumber = '',
	onCloseModal = () => {},
	verifySenderStatus = () => {},

	setModal,
}) {
	const [amount, setAmount] = useState('');
	const [mode, setMode] = useState('');
	const [loding, setLoding] = useState(false);
	const [pageMsg, setPageMsg] = useState({});
	const numberReg = /^[0-9]+$/;

	const onSubmit = () => {
		if (isEmpty(amount)) {
			setPageMsg({ type: 'error', text: 'Please enter amount' });
			return;
		} else if (isEmpty(mode)) {
			setPageMsg({ type: 'error', text: 'Please select mode' });
			return;
		}
		const params = {
			accountNumber: modalData?.account,
			beneficiaryID: modalData?.id,
			beneficiaryName: modalData?.name,
			ifscCode: modalData?.ifsc,
			merchantId: '',
			mobileNumber: mobileNumber,
			remittanceAmount: amount,
			route: mode,
			latitude: '24.034401',
			longitude: '74.780403',
			// "userId": "string"
		};
		setLoding(true);
		setAmount('');
		setMode('');
		sendMoneyRequest(params).then((response) => {
			setLoding(false);
			if (response?.success) {
				setPageMsg({ type: 'success', text: response?.msg });
				setModal({ name: 'TRANSACTION_SUCCESS_MODAL', modalData: response?.data });
			} else {
				setPageMsg({ type: 'error', text: response?.msg });
				if (response?.errorCodeList.length > 0) {
					setPageMsg({ type: 'error', text: response?.errorCodeList[0] });
				}
			}
			if (response?.success) {
				verifySenderStatus(mobileNumber);
			}
		});
	};

	return (
		<ModalWraper width="600px">
			<div className="modal pay-modal">
				<div className="modal-header">
					<div className="modal-title">Make Payment</div>
					<div className="modal-close" onClick={onCloseModal}>
						<IconClose />
					</div>
				</div>
				<div className="modal-body">
					<div className="pay-modal-form">
						{!isEmpty(pageMsg) && (
							<AlertWrapper className={pageMsg?.type}>
								<span className="close" onClick={() => setPageMsg({})}>
									×
								</span>
								{pageMsg?.text}
							</AlertWrapper>
						)}

						<div className="row">
							<div className="col-6">
								<div className="form-field">
									<input type="text" name="beneficiaryName" placeholder="Beneficiary Name" value={modalData?.name} disabled />
								</div>
							</div>
							<div className="col-6">
								<div className="form-field">
									<input type="text" name="bankName" placeholder="Bank Name" value={modalData?.bank} disabled />
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-6">
								<div className="form-field">
									<input type="text" name="ifscCode" placeholder="IFSC Code" value={modalData?.ifsc} disabled />
								</div>
							</div>
							<div className="col-6">
								<div className="form-field">
									<input type="text" name="accountNumber" placeholder="Account Number" value={modalData?.account} disabled />
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-6">
								<div className="form-field">
									<input
										type="text"
										name="amount"
										placeholder="Amount"
										value={amount}
										onChange={(e) => (!numberReg.test(e.target.value) && e.target.value ? '' : setAmount(e.target.value))}
									/>
								</div>
							</div>
							<div className="col-6">
								<div className="form-field">
									<select className="item-select mobile-operator" placeholder="Operator" name="operatorCode" value={mode} onChange={(e) => setMode(e.target.value)}>
										<option value="">Transfer Mode</option>
										{transferOptions.map((option, i) => {
											return (
												<option key={i} value={option?.value}>
													{option?.label}
												</option>
											);
										})}
									</select>
								</div>
							</div>
						</div>

						<div className="form-field"></div>
					</div>
				</div>
				<div className="modal-footer">
					<button className="button-solid" onClick={onSubmit}>
						SUBMIT
					</button>
				</div>
				{loding && <Loader />}
			</div>
		</ModalWraper>
	);
}
