import React from 'react';
import { ModalWraper } from '../UI/styledConstants';
import IconClose from '../../assets/images/IconClose';

export default function Conform({ setOverlayer, deleteUserData, deleteHandeler }) {
	return (
		<ModalWraper width="300px">
			<div className="modal otp-modal">
				<div className="modal-header">
					<div className="modal-title">DELETE</div>
					<div className="modal-close" onClick={() => setOverlayer(false)}>
						<IconClose />
					</div>
				</div>
				<div className="modal-body">
					<p>
						{' '}
						Are you sure you want to delete <span style={{ fontWeight: '700' }}>{deleteUserData?.name}</span>
					</p>
				</div>
				<div className="modal-footer" style={{ display: 'flex', justifyContent: 'space-between' }}>
					<button className="button-solid" onClick={() => deleteHandeler()}>
						Yes
					</button>
					<button className="button-solid" onClick={() => setOverlayer(false)}>
						No
					</button>
				</div>
			</div>
		</ModalWraper>
	);
}
