import Request from './Request';
import config from './config';

const apiRequest = new Request(
	() => {},
	() => {},
	() => {}
);

export const userAuthRequest = (params) => {
	return apiRequest.post(`${config.API_BASEPATH}${config.login.API_AUTHENTICATE}`, params);
};
export const getApiPermission = (param) => {
	return apiRequest.get(`${config.API_BASEPATH}${config.login.API_PERMISSION}/${param}`);
};

export const getUserProfileRequest = (params = {}) => {
	return apiRequest.get(`${config.API_BASEPATH}${config.login.USER_PROFILE}`, params);
};

export const getPlanDetails = (params) => {
	return apiRequest.get(`${config.API_BASEPATH}${config.mobileRecharge.PLANS}/${params.zone || 'NE'}/${params.operator}`, {});
};
export const getMobileOperatorList = () => {
	return apiRequest.get(`${config.API_BASEPATH}${config.mobileRecharge.OPERATOR}`);
};
export const getMobileZoneList = () => {
	return apiRequest.get(`${config.API_BASEPATH}${config.mobileRecharge.ZONE}`);
};
export const handleResetNumberOtp = (param) => {
	return apiRequest.post(`${config.API_BASEPATH}${config.login.RESET_PASSWORD}`, param);
};

export const otpVerificationHandlerResetPassword = (params) => {
	return apiRequest.post(`${config.API_BASEPATH}${config.login.VERIFY_OTP}`, params);
};
export const mobileRechargeRequest = (params) => {
	return apiRequest.post(`${config.API_BASEPATH}${config.mobileRecharge.RECHARGE}`, params);
};

export const getZoneList = (params) => {
	return apiRequest.get(`${config.API_BASEPATH}${config.mobileRecharge.PLANS}/${params.zone || 'NE'}/${params.operator}`, {});
};

export const getTransactionHistoryRequest = (params) => {
	return apiRequest.post(`${config.API_BASEPATH}${config.transactionHistory.TRANSACTION_HISTORY}`, params);
};

export const getUserWalletRequest = (params) => {
	return apiRequest.get(`${config.API_BASEPATH}${config.wallet.USER_WALLET}`, params);
};

export const getUserWalletTransactionRequest = (params) => {
	return apiRequest.post(`${config.API_BASEPATH}${config.wallet.USER_WALLET_TRANSACTIONS}`, params);
};

export const getProviderListRequest = (params) => {
	return apiRequest.get(`${config.API_BASEPATH}${config.payBills.GET_PROVIDER_LIST_URL}/${params?.providerType}`);
};

export const getProviderDetailRequest = (params) => {
	return apiRequest.get(`${config.API_BASEPATH}${config.payBills.GET_PROVIDER_DETAILS_URL}/${params}`);
};

export const fetchUtilityBillRequest = (params) => {
	return apiRequest.post(`${config.API_BASEPATH}${config.payBills.FETCH_BILL_URL}`, params);
};

export const payUtilityBillRequest = (params) => {
	return apiRequest.post(`${config.API_BASEPATH}${config.payBills.PAY_BILL_URL}`, params);
};

export const verifySenderStatusRequest = (mobileNumber) => {
	return apiRequest.get(`${config.API_BASEPATH}${config.sendMoney.VERIFY_SENDER_STATUS_URL}/${mobileNumber}`);
};

export const verifySenderRequest = (params) => {
	return apiRequest.post(`${config.API_BASEPATH}${config.sendMoney.VERIFY_SENDER_URL}`, params);
};

export const registerSenderRequest = (params) => {
	return apiRequest.post(`${config.API_BASEPATH}${config.sendMoney.REGISTER_SENDER_URL}`, params);
};
export const sendMoneyRequest = (params) => {
	return apiRequest.post(`${config.API_BASEPATH}${config.sendMoney.SEND_MONEY_URL}`, params);
};
export const addBeneficiaryRequest = (params) => {
	return apiRequest.post(`${config.API_BASEPATH}${config.sendMoney.ADD_BENEFICIARY_URL}`, params);
};
export const deleteBeneficiaryRequest = (params) => {
	return apiRequest.delete(`${config.API_BASEPATH}${config.sendMoney.DELETE_BENEFICIARY_URL}`, params);
};

export const getAepsBankList = () => {
	return apiRequest.get(`${config.API_BASEPATH}${config.aeps.AEPS_BANK_LIST}`);
};

export const getCheckAepsStatus = () => {
	return apiRequest.get(`${config.API_BASEPATH}${config.aeps.CHECK_AEPS_STATUS}`);
};

export const cashwithdrawal = (params) => {
	return apiRequest.post(`${config.API_BASEPATH}${config.aeps.CASH_WITHDRAWAL}`, params);
};
export const merchantLoginFingerprient = (params) => {
	return apiRequest.post(`${config.API_BASEPATH}${config.aeps.MERCHANT_LOGIN_FINGERPRINT}`, params);
};

export const balanceEnquiry = (params) => {
	return apiRequest.post(`${config.API_BASEPATH}${config.aeps.BALANCE_ENQUIRY}`, params);
};

export const miniStatement = (params) => {
	return apiRequest.post(`${config.API_BASEPATH}${config.aeps.MINI_STATEMENT}`, params);
};
export const rechargeDth = (params) => {
	return apiRequest.post(`${config.API_BASEPATH}${config.dth.DTH_RECHARGE}`, params);
};

export const getDthPlanDetails = (params) => {
	return apiRequest.get(`${config.API_BASEPATH}${config.dth.DTH_PLANS}${params.operatorCode}`);
};

export const creditCardPayBill = (params) => {
	return apiRequest.post(`${config.API_BASEPATH}${config.creditCard.CREDIT_CARD_BILL_PAY}`, params);
};
