import React from 'react';
import './GrowYourBussion.css';
import user from '../../assets/images/user-dil.svg';
import bag from '../../assets/images/badge-check.svg';
import shield from '../../assets/images/shield-check.svg';
import smile from '../../assets/images/smile.svg';
import mobile from '../../assets/images/mobile-1.png';
import img from '../../assets/images/img-10.png';
import img2 from '../../assets/images/cuse-2.svg';

const GrowYourBussion = () => {
	return (
		<div className="GrowYourBussionWrapper">
			<div className="container">
				<div className="GrowYourBussion-wrapper ">
					<div className="top-wrapper flex gap-30 justify-center">
					<div className="left self-center">
							<img src={img2} alt="mobile" />
						</div>
						<div className="right">
							<h2 className="heading-2">
							
							Recharges & Bill Payments
							</h2>
							<p className="h3">
							Lightning fast payments, exciting rewards and seamless transactions on every recharge & bill payment.
							</p>

						    <p style={{fontSize:"15px", fontWeight:"400"}}>
							We provide digital platform to process transaction to our client. We offer better success rates, relationships and business networks that will help further grow and augment your business volumes. We facilitate seamless transactions with a multitude of entrepreneurs and businesses, and aim to sustain and strengthen, develop and innovate digital payment services in India.
							</p>
						</div>
						
						
					</div>

					<div className="middle-wrapper">
						<div className='middle-wrapper-top flex'>
                            <div className="left text-left self-center">
								<h2>Trusted, Safe & Secure</h2>
								<p>End-to-end payment solutions for businesses. Enables acceptance of payments by UPI, Cards, Digital Wallets, Net Banking and 100+ other channels</p>
							</div>
                            <div className="right">
								<img src={img} alt="img" />
							</div>
						</div>
						<div className=" card-wrapper center gap-30">
							<div className="box">
								<div className="img center">
									<img src={bag} alt="shield" />
								</div>
								<h2>Fast Bill Pay</h2>
								<p>Pay Fast Be Smart</p>
							</div>
							<div className="box">
								<div className="img center">
									<img src={user} alt="user" />
								</div>
								<h2>Trust pay</h2>
								<p>Pay Fast Be Smart</p>
							</div>
							<div className="box">
								<div className="img center">
									<img src={shield} alt="shield" />
								</div>
								<h2>Secure Payments</h2>
								<p>Moving your card details to a much more secured place.</p>
							</div>
							<div className="box box-4">
								<div className="img center">
									<img src={smile} alt="smile" />
								</div>
								<h2>24X7 Support</h2>
								<p>We're here to help. Have a query and need help ? Click here</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default GrowYourBussion;
