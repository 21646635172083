
import React from 'react';
import { Wrapper } from './style';
import img from '../../assets/images/bharatBanner.png'


export default function OfferBanner() {
	return (
		<Wrapper className='d-m-none' img={img}>
			<div>
				<div className="banner-text">
				<h1>
					Great Offer <br /> Great Savings
				</h1>
				<div className="flat-text">Flat Upto 50% Cashback</div>
				<button className="button-solid">Explore Now</button>
				<i>*T&C apply.</i>
			</div>
			</div>
		</Wrapper>
	);
}